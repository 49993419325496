import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import './footer.scss';

interface IFooter {
  allSanityFooter: {
    nodes: {
      footerTitle: string;
      footerMessage: string;
      facebook: string;
      email: string;
      phone: string;
    }[];
  };
}

const Footer = () => {
  const query: IFooter = useStaticQuery(
    graphql`
      query {
        allSanityFooter {
          nodes {
            phone
            email
            facebook
            footerTitle
            footerMessage
          }
        }
      }
    `
  );
  const footerContent = query.allSanityFooter?.nodes[0];
  return <div className="footer">
    {
      footerContent.footerTitle &&
        <p className="footer-title" >{ footerContent.footerTitle }</p>
    }
    {
      footerContent.footerMessage &&
        <p className="footer-text">{ footerContent.footerMessage }</p>
    }
    {
      (footerContent.phone || footerContent.email) &&
        <div className="footer-social">
          <div className="footer-social-container">
            {
              footerContent.facebook &&
                <a href={footerContent.facebook}>Facebook</a>
            }
            {
              footerContent.facebook && footerContent.email &&
                <span className="footer-social-separator">|</span>
            }
            {
              footerContent.email &&
                <a href={'mailto:'+footerContent.email}>Email</a>
            }
            {
              (footerContent.facebook || footerContent.email) && footerContent.phone &&
                <span className="footer-social-separator">|</span>
            }
            {
              footerContent.phone &&
                <a href={'tel:'+footerContent.phone}>Phone</a>
            }
          </div>
        </div>
    }
  </div>
}

export default Footer