import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { IButton } from '../../contracts/buttons/ibutton';
import './header.scss';

interface IHeader {
  allSanityHeader: {
    nodes: {
      title: string;
      navButtons: IButton[];
    }[];
  };
}

const Header = () => {
  const query: IHeader = useStaticQuery(
    graphql`
      query {
        allSanityHeader {
          nodes {
            title
            navButtons {
              buttonUrl
              buttonText
              buttonColor
              _key
            }
          }
        }
      }
    `
  );
  const headerData = query.allSanityHeader.nodes[0];
  return <header className="header">
    {
      (headerData.title || headerData.navButtons.length > 0) &&
        <div className="navigation-bar">
          <div>
            {
              headerData.title &&
                <h1 className="header-title">{ headerData.title }</h1>
            }
          </div>
          {
            headerData.navButtons.length > 0 &&
              <div>
                {
                  headerData.navButtons.map((navButton: IButton) => {
                    if (navButton.buttonUrl.startsWith('https')) {
                      return <a key={navButton._key} href={navButton.buttonUrl} className={'btn btn-' + navButton.buttonColor}>{ navButton.buttonText }</a>
                    } else {
                      return <Link key={navButton._key} to={navButton.buttonUrl} className={'btn btn-' + navButton.buttonColor}>{ navButton.buttonText }</Link>
                    }
                  })
                }
              </div>
          }
        </div>
    }
  </header>
}

export default Header
