import React, { ReactElement } from 'react';
import '../styles/global.scss';
import Footer from './footer/footer';
import Header from './header/header';

interface DataProps {
  children: ReactElement[];
}

const Layout: React.FC<DataProps> = ({children}) => (
  <div>
    <Header></Header>
    <main>{children}</main>
    <Footer></Footer>
  </div>
)

export default Layout
